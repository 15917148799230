<template>
  <fragment v-if="!isLoading">
    <v-form class="multi-col-validation pt-5">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col
              align="center"
              cols="4"
            >
              <v-avatar
                v-if="avatar === null"
                size="120px"
              >
                <img
                  src="@/assets/images/avatars/1.png"
                  class="rounded"
                  alt="avatar"
                />
              </v-avatar>
              <fragment v-else>
                <v-avatar
                  v-if="changeAvatar"
                  size="120px"
                >
                  <img
                    :src="previewImage === null ? rutaPublic + avatar : previewImage"
                    class="rounded"
                    alt="avatar"
                  />
                </v-avatar>
                <v-avatar
                  v-else
                  size="120px"
                >
                  <img
                    :src="rutaPublic + avatar"
                    class="rounded"
                    alt="avatar"
                  />
                </v-avatar>
              </fragment>
              <p
                v-if="editUser"
                class="pt-5 text--primary"
              >
                <v-row
                  v-if="!onlyShow"
                  no-gutters
                >
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-file-input
                      v-if="changeAvatar"
                      v-model="avatar"
                      accept="image/*"
                      outlined
                      dense
                      label="Avatar"
                      hide-details
                      @change="setAvatar"
                      @click:clear="clearAvatar"
                    ></v-file-input>
                    <a
                      v-else
                      style="cursor: pointer"
                      color="primary"
                      @click="changeAvatar = !changeAvatar"
                    >{{ $t('lbl.changeFoto') }}</a>
                  </v-col>
                </v-row>
              </p>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="6">
              <div v-if="!editUser">
                <h4 class="font-weight-medium mb-1">
                  {{ model.name }} {{ model.apellidos }}
                </h4>
                <div class="d-flex align-center justify-space-between">
                  <span class="user-verification-number">{{ model.email }}</span>
                  <div>
                    <v-btn
                      icon
                      small
                      color="secondary"
                      @click="editUser = !editUser"
                    >
                      <v-icon size="20">
                        {{ icons.mdiSquareEditOutline }}
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
              <fragment v-else>
                <v-row>
                  <v-col
                    cols="12"
                    md="8"
                  >
                    <v-text-field
                      v-model="model.name"
                      :label="$t('lbl.name')"
                      outlined
                      dense
                      :placeholder="$t('lbl.name')"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="8"
                  >
                    <v-text-field
                      v-model="model.apellidos"
                      :label="$t('lbl.apellidos')"
                      outlined
                      dense
                      :placeholder="$t('lbl.apellidos')"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="8"
                  >
                    <v-text-field
                      v-model="model.email"
                      :label="$t('lbl.email')"
                      outlined
                      dense
                      :placeholder="$t('lbl.email')"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-btn
                      large
                      color="primary"
                      :loading="loading"
                      @click="save"
                    >
                      {{ $t('btn.send') }}
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-btn
                      large
                      color="error"
                      @click="cancele"
                    >
                      {{ $t('btn.cancele') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </fragment>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// eslint-disable-next-line import/no-unresolved
// import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'

export default {
  // components: {
  //   AppCardCode,
  // },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      avatar: null,
      previewImage: null,
      changeAvatar: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      roles: [],
      editUser: false,
      model: {
        avatar: null,
        id: null,
        name: null,
        apellidos: null,
        email: null,
        role_id: null,
        active: true,
      },
      password: '',
      confirmation_password: '',
      permisos: [],
      permisosItem: [],
      loading: false,
      onlyShow: false,
      rules: {
        min: v => v.length >= 6 || this.$t('rules.min6'),
      },
      show: false,
    }
  },
  computed: {
    ...mapState({
      userUpdate: state => state.app.userUpdate,
      userUpdateAvatar: state => state.app.userUpdateAvatar,
    }),
    btnTitle() {
      return sessionStorage.getItem('user-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    title() {
      return sessionStorage.getItem('user-id') === null ? this.$t('btn.create') : this.$t('btn.edit')
    },
  },
  created() {
    this.getRoles()
    this.profile()
  },
  methods: {
    ...mapMutations(['setUserUpdate', 'setUserUpdateAvatar']),
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          }
          this.model = response.data.data
          this.avatar = this.model.avatar
          this.changeAvatar = false
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },

    getRoles() {
      this.axios.get(`role?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
        },
      }).then(res => {
        this.roles = res.data.data
      })
    },
    setAvatar() {
      this.previewImage = URL.createObjectURL(this.avatar)
    },
    clearAvatar() {
      this.previewImage = null
      this.changeAvatar = !this.changeAvatar
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          }
          this.avatar = response.data.data.avatar
        })
    },
    save() {
      if (this.model.name && this.model.apellidos && this.model.email) {
        this.loading = true
        const json = {
          name: this.model.name,
          apellidos: this.model.apellidos,
          email: this.model.email,
        }

        const formData = new FormData()
        formData.append('data', JSON.stringify(json))
        if (this.avatar) {
          formData.append('avatar', this.avatar)
          json.avatar = this.avatar
        }

        this.axios
          .post('profile', formData, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(response => {
            // eslint-disable-next-line no-empty
            if (response.data.success === false) { }
          })
          .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
          .finally(() => window.location.reload())
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    cancele() {
      this.previewImage = null
      this.changeAvatar = false
      this.editUser = false
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          }
          this.model = response.data.data
          this.avatar = this.model.avatar
        })
    },
  },
}
</script>
