<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
        md="12"
        lg="12"
      >
        <v-tabs
          v-model="userTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab>
            <v-icon
              size="20"
              class="me-3"
            >
              {{ icon.mdiAccountOutline }}
            </v-icon>
            <span>{{ $t('lbl.info') }}</span>
          </v-tab>
          <v-tab>
            <v-icon
              size="20"
              class="me-3"
            >
              {{ icon.mdiLockOutline }}
            </v-icon>
            <span>{{ $t('lbl.password') }}</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items
          id="user-tabs-content"
          v-model="userTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            <user-tab-info></user-tab-info>
          </v-tab-item>
          <v-tab-item>
            <user-tab-security></user-tab-security>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiLockOutline } from '@mdi/js'

import UserTabInfo from './user-tab-info/UserTabInfo.vue'
import UserTabSecurity from './user-tab-security/UserTabSecurity.vue'

// import UserTabBillingsPlans from './user-tab-billings-plans/UserTabBillingsPlans.vue'
// import UserTabNotifications from './user-tab-notifications/UserTabNotifications.vue'
// import UserTabConnections from './user-tab-connections/UserTabConnections.vue'

export default {
  components: {
    UserTabInfo,
    UserTabSecurity,

    /* UserTabBillingsPlans,
    UserTabNotifications,
    UserTabConnections, */
  },
  data() {
    return {
      userTab: null,
      icon: {
        mdiAccountOutline,
        mdiLockOutline,
      },
    }
  },

  /* setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const userData = ref({})
    const userTab = ref(null)
    const selectedPlan = ref('')
    const isPlanUpgradeDialogVisible = ref(false)

    store
      .dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
      .then(response => {
        userData.value = response.data
        selectedPlan.value = response.data.currentPlan
      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = {}
        }
      })

    const plansList = [
      { text: 'Basic - $0/month', value: 'basic' },
      { text: 'Standard - $99/month', value: 'standard' },
      { text: 'Enterprise - $499/month', value: 'enterprise' },
      { text: 'Company - $999/month', value: 'company' },
    ]

    const tabs = [
      { icon: mdiAccountOutline, title: 'Overview' },
      { icon: mdiLockOutline, title: 'Security' },
      { icon: mdiBookmarkOutline, title: 'Billing & Plan' },
      { icon: mdiBellOutline, title: 'Notifications' },
      { icon: mdiLinkVariant, title: 'Connections' },
    ]

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }

    return {
      tabs,
      userTab,
      plansList,
      selectedPlan,
      isPlanUpgradeDialogVisible,
      userData,
      resolveCurrentPlanValue,
    }
  }, */
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
